<template>
  <div class="succeed">
    <!-- <img src="~assets/images/icon/succeed.png" alt="" />
    <div class="hint">Congratulations, Submitted Successfully</div>
    <div class="path">
      <span @click="path">View Order</span>
    </div> -->

    <div class="title_wrap" v-if="info">
      <div class="title_info">
        <p><span>SPECIAL T IMPORTS INC</span></p>
        <p><span>P O BOX 57</span></p>
        <p><span>WOODLAND CA 95776</span></p>
      </div>
      <div class="title_table">
        <span>Sales Order</span>
        <table border="1" cellspacing="0" cellpadding="8">
          <thead>
            <tr>
              <th>Date</th>
              <th>S.N.NO</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ info.create_time }}</td>
              <td>{{ info.ordersn }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="bill_ship_wrap" v-if="info">
      <div class="bill_wrap">
        <table border="1" cellspacing="0" cellpadding="8">
          <thead>
            <tr>
              <th align="left">&nbsp;Billing To</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>{{ info.billing.company }}</p>
                <p>
                  {{ info.billing.first_name }} {{ info.billing.last_name }}
                </p>
                <P>{{ info.billing.address1 }}</P>
                <p>
                  {{ info.billing.city }},{{ info.billing.state }},{{
                    info.billing.zip_code
                  }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="ship_wrap">
        <table border="1" cellspacing="0" cellpadding="8">
          <thead>
            <tr>
              <th align="left">&nbsp;Shipping To</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>{{ info.shipping.company }}</p>
                <p>
                  {{ info.shipping.first_name }} {{ info.shipping.last_name }}
                </p>
                <p>{{ info.shipping.address1 }}</p>
                <p>
                  {{ info.shipping.city }},{{ info.shipping.state }},{{
                    info.shipping.zip_code
                  }}
                </p>
                <p>
                  {{ info.shipping.phone }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="other_wrap" v-if="info">
      <table border="1" cellspacing="0" cellpadding="8">
        <thead>
          <tr>
            <th>P.O.NO.</th>
            <th>Terms</th>
            <th>Rep</th>
            <th>Ship Date</th>
            <th>Ship Via</th>
            <th>Cancel Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="font-weight: 0">{{ info.po_no }}</td>
            <td>{{ info.terms }}</td>
            <td>{{ info.rep }}</td>
            <td>{{ info.ship_date }}</td>
            <td>{{ info.ship_via }}</td>
            <td>{{ info.cancel_date }}</td>
          </tr>
          <tr>
            <td style="font-weight: 700">Note</td>
            <td colspan="6">{{ info.note }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="goods_wrap" v-if="info">
      <table border="1" cellspacing="0" cellpadding="8">
        <thead>
          <tr>
            <th>Item</th>
            <th>Description</th>
            <th>Ordered</th>
            <th>U/M</th>
            <th>Rate</th>
            <th>Barcode</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <!-- Goods -->
          <tr v-for="item in info.goods_list">
            <td>{{ item.item }}</td>
            <td style="text-align: left">{{ item.description }}</td>
            <td>{{ item.ordered }}</td>
            <td>{{ item.uom }}</td>
            <td>{{ item.rate }}</td>
            <td>{{ item.barcode }}</td>
            <td>{{ item.amount }}</td>
          </tr>

          <tr style="height: 1rem; border-top: none">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td colspan="4"></td>
            <td colspan="3">
              <div class="total">
                <span>Total</span>
                <span>${{ info.total }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { getOrdersPrint } from "network/order";
export default {
  data() {
    return {
      info: null,
    };
  },
  created() {
    getOrdersPrint(this.$route.query.id).then((res) => {
      if (res.data.code == 1) {
        this.info = res.data.data;
      }
    });
  },
  methods: {
    path() {
      this.$router.push("/Member");
    },
  },
};
</script>
<style scoped>
/* p {
  margin: 0;
}
.succeed {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
  text-align: center;
}
img {
  width: 2.5rem;
  height: 2.5rem;
  margin-top: 2.5rem;
}
.hint {
  color: #333333;
  font-size: 0.5rem;
  font-weight: 600;
  margin: 0.5rem 0;
}
.path {
  padding: 1rem 0;
}
.path span {
  color: #ff7f00;
  font-size: 0.35rem;
  text-decoration: underline;
  cursor: pointer;
} */
.succeed {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
  text-align: center;
  font-size: 0.4rem;
}
tr {
  border: 0;
}
.title_wrap {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}
.title_info {
  display: flex;
  flex-direction: column;
}
.title_info p {
  font-size: 0.5rem;
  margin: 0;
}
.title_info p:nth-child(1) {
  margin-bottom: 0.5rem;
}
.title_table {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.title_table > span {
  font-weight: 900;
  font-size: 0.6rem;
  margin-bottom: 0.3rem;
}
.title_table table thead tr th {
  width: 5rem;
}
.bill_ship_wrap {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
}
.bill_ship_wrap .bill_wrap,
.ship_wrap {
  width: 48%;
}
.bill_ship_wrap .bill_wrap table,
.ship_wrap table {
  width: 100%;
  height: 100%;
}
.bill_ship_wrap div:nth-child(1) {
  margin-right: 2rem;
}
td p {
  margin: 0.1rem 0;
}
.other_wrap {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  padding-bottom: 0;
}
.other_wrap table tbody tr td {
  width: 11.5rem;
  text-align: center;
}

.goods_wrap {
  padding: 0 0.5rem;
  margin-bottom: 1rem;
}
.goods_wrap table {
  width: 100%;
}
.goods_wrap table thead tr {
  background-color: blanchedalmond;
  border-bottom: 1px solid black;
}
.goods_wrap table tbody tr:last-child td {
  border-top: 1px solid black;
}
.goods_wrap table tbody td {
  border-top: 0;
  border-bottom: 0;
  text-align: center;
}
.total {
  width: 94%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3%;
}
.total span:nth-child(1) {
  font-size: 0.4rem;
}
tr {
  border: 1px solid black;
}
.goods_wrap tr {
  border: none;
}
td {
  border: 1px solid black;
}
</style>
